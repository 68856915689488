.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }
.material-icons-outlined.md-72 { font-size: 72px; }
.material-icons-outlined.md-100 { font-size: 100px; }


.App {
  color: #323133;
}
